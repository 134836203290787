import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment-timezone';
import { BaseComponent } from 'src/app/components/base/base.component';
import { DatumViewModel } from 'src/app/models/datum_model';
import { DeviceViewModel } from 'src/app/models/device_model';
import { MappingViewModel } from 'src/app/models/mapping_model';
import { UserViewModel } from 'src/app/models/user_model';
import { DataService } from 'src/app/services/data.service';
import { UnitsService } from 'src/app/services/units.service';
import { DeviceDataPeriods, ROUTE_HOME, ROUTE_USER, ViewUiState } from 'src/app/utils/constants';
import { ScrollingService } from 'src/app/services/scrolling.service';
import * as crypto from 'src/app/utils/crypto';
import { environment } from 'src/environments/environment';
import { GroupViewModel } from 'src/app/models/group_model';

@Component({
  selector: 'app-report-daily',
  templateUrl: './report-daily.component.html',
  styleUrls: ['./report-daily.component.scss']
})
export class ReportDailyComponent extends BaseComponent implements OnInit {
  scrollingDown: boolean = false; // Used to hide toolbar, tabs and move Fab
  title="Daily Report"

  userId: string;
  user: UserViewModel;
  mappings: Array<MappingViewModel>;
  selectedMapping: MappingViewModel;
  selectedDate: moment.Moment;
  selectedTarget: DeviceViewModel | GroupViewModel;
  bannerText: string;
  unitDetails = this.butler.localService.getUnitDetails();

  dayDatum: DatumViewModel;
  dayShifts: DatumViewModel[];
  dayBeforeDatum: DatumViewModel;
  dayBeforeShifts: DatumViewModel[];

  inProgressCounter = 0;

  constructor(
    private route: ActivatedRoute,
    scrollingService: ScrollingService,
    router: Router,
    butler: DataService,
    private unitsService: UnitsService,
  ) {
    super(scrollingService, router, butler);
    this.userId = this.route.snapshot.queryParams.id;
    if(!this.userId) this.userId = this.butler.localService.getLocalUserId();
    if(this.route.snapshot.queryParams.date) {
      this.selectedDate = moment(this.route.snapshot.queryParams.date);
    }
    if(!this.selectedDate?.isValid() || this.selectedDate.isAfter(moment.tz('Asia/Kolkata'))) {
      this.selectedDate = moment.tz('Asia/Kolkata').startOf('day').subtract(1, 'day');
    }
    this.handleBanner();
    this.unitsService.getUnits$().subscribe(
      () => this.unitDetails = this.butler.localService.getUnitDetails()
    )
  }

  handleBanner() {
    // Get query params, if banner present, check that it is verified, if yes, pass it to the view.
    if(this.route.snapshot.queryParams.banner && this.route.snapshot.queryParams.bannerSignature) {
      const bannerText = this.route.snapshot.queryParams.banner;
      const bannerSignature = this.route.snapshot.queryParams.bannerSignature;
      const isVerified = crypto.verifySign(bannerText, bannerSignature, environment.bannerPubKey);
      if(isVerified) this.bannerText = bannerText;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { banner: null, bannerSignature: null },
        queryParamsHandling: 'merge'
      }); // Remove from url
    }
  }

  ngOnInit(): void {
    this.fetchData()
  }

  private async fetchData() {
    try {
      this.uiState = ViewUiState.loading;
      const currentUserId = this.butler.localService.getLocalUserId();
      const currentUserOrg = this.butler.localService.getUserOrg();
      const user = await this.butler.userApi.getCachedUser(this.userId);
      this.user = new UserViewModel(user, currentUserId, currentUserOrg);
      // Fetch the devices and groups the user can see from the server
      const mappings = await this.butler.userApi.getUserMappings(this.userId);
      this.mappings = mappings.map(m => new MappingViewModel(m));
      // If a valid device id is present in query params, select that device
      if(this.route.snapshot.queryParams.device) {
        this.selectedMapping = this.mappings.find(m => m.deviceExternalId === this.route.snapshot.queryParams.device);
      }
      // Default to the first mapping if no device is selected
      if(!this.selectedMapping) {
        this.selectedMapping = this.mappings[0];
      }
      await this.fetchStatistics();
      this.uiState = ViewUiState.data;
    } catch(error) {
      this.errorText = "Failed to fetch user, please try again.";
      this.uiState = ViewUiState.error;
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }

  async updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        device: this.selectedMapping.deviceExternalId,
        date: this.selectedDate.format('YYYY-MM-DD')
      },
      queryParamsHandling: 'merge'
    });
  }

  private async fetchStatistics() {
    try {
      this.updateQueryParams();
      this.uiState = ViewUiState.loading;
      // Request the data for the first device from the server
      if(this.selectedMapping.deviceExternalId.startsWith('group')) {
        const group = await this.butler.groupApi.getGroup(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new GroupViewModel(group);
      } else {
        const device = await this.butler.deviceApi.getDevice(this.selectedMapping.deviceExternalId);
        this.selectedTarget = new DeviceViewModel(device);
      }
      // Dates
      const dayStart = this.selectedDate.clone().startOf('day').subtract(1, 'day')
      const dayEnd = this.selectedDate.clone().startOf('day');
      const dayBeforeStart = dayStart.clone().subtract(1, 'day')
      const dayBeforeEnd = dayEnd.clone().subtract(1, 'day');
      // Fetch
      const dayResp = await this.butler.dataApi.getDailyData(this.userId, dayStart, dayEnd, this.selectedMapping.deviceExternalId);
      const dayShiftsResp = await this.butler.dataApi.getShiftData(this.userId, dayStart, dayEnd, this.selectedMapping.deviceExternalId);
      const dayBeforeResp = await this.butler.dataApi.getDailyData(this.userId, dayBeforeStart, dayBeforeEnd, this.selectedMapping.deviceExternalId);
      const dayBeforeShiftsResp = await this.butler.dataApi.getShiftData(this.userId, dayBeforeStart, dayBeforeEnd, this.selectedMapping.deviceExternalId);
      // Sanitize
      this.dayDatum = DatumViewModel.sanitizeDatum(DeviceDataPeriods.daily, dayStart, dayEnd, dayResp[0], this.selectedTarget);
      this.dayBeforeDatum = DatumViewModel.sanitizeDatum(DeviceDataPeriods.daily, dayBeforeStart, dayBeforeEnd, dayBeforeResp[0], this.selectedTarget);
      this.dayShifts = DatumViewModel.sanitizeData(DeviceDataPeriods.shift, dayStart, dayEnd, dayShiftsResp, this.selectedTarget);
      this.dayBeforeShifts = DatumViewModel.sanitizeData(DeviceDataPeriods.shift, dayBeforeStart, dayBeforeEnd, dayBeforeShiftsResp, this.selectedTarget);
      // Show UI
      this.uiState = ViewUiState.data;
    } catch(error) {
      console.error(error);
      this.errorText = "Failed to fetch device statistics, please try again.";
      this.uiState = ViewUiState.error;
      this.snackBar = {message: `${error.message}`, action: 'Close', config: { duration: 4000 }};
    }
  }

  public async onClickMappingChange(mapping: MappingViewModel) {
    this.selectedMapping = mapping;
    this.fetchStatistics();
  }

  public async onClickDateChange(date: any) {
    this.selectedDate = date.value.startOf('day');
    this.fetchStatistics();
  }

  public async onClickReset() {
    this.fetchData();
  }

  public onClickBack() {
    if(this.butler.localService.isUserKritsnam()) {
      this.router.navigate([ROUTE_USER, this.userId], { queryParamsHandling: 'preserve' });
    } else {
      this.router.navigate([ROUTE_HOME], { queryParamsHandling: 'preserve' });
    }
  }
}