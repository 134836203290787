import { Injectable } from '@angular/core';
import { NumberCardComponent } from '@swimlane/ngx-charts';
import moment from 'moment-timezone';
import { DatumModel } from 'src/app/models/datum_model';
import { BackendService } from 'src/app/services/backend.service';


@Injectable({
  providedIn: 'root'
})
export class DataApi {

  constructor(private backendService: BackendService) {}

  public async getRawData(userId: string, startTime: moment.Moment, endTime: moment.Moment, deviceExternalId: string): Promise<{serial_number:string, time: Date, forward_flow: number, workhour: number}[]> {
    const post = {
      startTime: startTime.format(),
      endTime: endTime.format(),
      userId: userId,
    };
    let results: Array<{serial_number: string, time: Date, forward_flow: number, workhour: number}>;
    results = await this.backendService.post(`/devices/${deviceExternalId}/data/raw`, post).toPromise();
    for(const r of results) {
      //r.forward_flow = Math.round(r.forward_flow / 1000 * 100) / 100; // Two decimal places lt to m3
      r.forward_flow = r.forward_flow / 1000
    }
    return results;
  }

  public async getShiftData(userId: string, startTime: moment.Moment, endTime: moment.Moment, deviceExternalId: string): Promise<DatumModel[]> {
    const post = {
      startTime: startTime.format(),
      endTime: endTime.format(),
      userId: userId,
    };
    const data = await this.backendService.post(`/devices/${deviceExternalId}/data/shift`, post).toPromise();
    console.log("response", data);
    const results: DatumModel[] = data.map(datum => DatumModel.parse(datum));
    return results;
  }

  public async  getDailyData(userId: string, startTime: moment.Moment, endTime: moment.Moment, deviceExternalId: string): Promise<DatumModel[]> {
    const post = {
      startTime: startTime.format(),
      endTime: endTime.format(),
      userId: userId,
    };
    const data = await this.backendService.post(`/devices/${deviceExternalId}/data/daily`, post).toPromise();
    console.log("responsedailry", data);
    const results: DatumModel[] = data.map(datum => DatumModel.parse(datum));
    return results;
  }

  public async getWeeklyData(userId: string, startTime: moment.Moment, endTime: moment.Moment, deviceExternalId: string): Promise<DatumModel[]> {
    const post = {
      startTime: startTime.format(),
      endTime: endTime.format(),
      userId: userId,
    };
    const data = await this.backendService.post(`/devices/${deviceExternalId}/data/weekly`, post).toPromise();
    const results: DatumModel[] = data.map(datum => DatumModel.parse(datum));
    return results;
  }

  public async getMonthlyData(userId: string, startTime: moment.Moment, endTime: moment.Moment, deviceExternalId: string): Promise<DatumModel[]> {
    const post = {
      startTime: startTime.format(),
      endTime: endTime.format(),
      userId: userId,
    };
    const data = await this.backendService.post(`/devices/${deviceExternalId}/data/monthly`, post).toPromise();
    const results: DatumModel[] = data.map(datum => DatumModel.parse(datum));
    return results;
  }
}
